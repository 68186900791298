import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import Seo from '../components/molecules/Seo'
import LinkCard from '../components/organisms/LinkCard'
import Container from '../components/atoms/Container'
import LinkNavigation from '../components/molecules/LinkNavigation'

const LinkHolder = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-top: 2.5rem;
`

function Links({data}) {
    const {
        title,
        description,
        author,
        ogDescription,
        ogTitle,
        ogImage,
        ogUrl,
        link
    } = data.contentfulLinksPage

    return (
        <>
            <Seo title={title} description={description} author={author} ogDescription={ogDescription} ogTitle={ogTitle}
                 ogImage={ogImage} ogUrl={ogUrl}/>
            <Container>
                <LinkNavigation/>
                <LinkHolder>
                    {link && link.map((item, index) => {
                        return <LinkCard key={index} url={item.url} date={item.date} image={item.image}
                                         title={item.title}/>
                    })}
                </LinkHolder>
            </Container>
        </>
    )
}

export const query = graphql`
  query linksQuery {
    contentfulLinksPage {
      title
      description
      author
      ogTitle
      ogDescription
      ogUrl
      ogImage {
        gatsbyImageData
        title
        description
      }
      link {
        title
        date(formatString: "DD MMMM YYYY")
        image {
            gatsbyImageData
            title
            description
        }
        url
      }
    }
  }
`

export default Links