import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const Holder = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding-left: 0;
  border-bottom: 2px solid ${props => props.theme.colours.blue};
`

const ListItem = styled.li`
  > a {
    text-decoration: none;
    color: ${props => props.theme.colours.blue};

    h3 {
      margin-right: 1rem;
    }
  }
`

function LinkNavigation() {
    return (
        <Holder>
            <ListItem><Link to="/"><h3>Website</h3></Link></ListItem>
            <ListItem><a href="https://www.linkedin.com/company/parallellinesdesign/"
                         rel="noreferrer noopener" target="_blank"><h3>LinkedIn</h3></a></ListItem>
            <ListItem><a href="https://confirmsubscription.com/h/y/665AAA106577F8C8" rel="noreferrer noopener"
                         target="_blank"><h3>Newsletter</h3></a></ListItem>
        </Holder>
    )
}

export default LinkNavigation