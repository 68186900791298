import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {GatsbyImage} from 'gatsby-plugin-image'

const Holder = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`

const ImageWrapper = styled.div`
  max-width: 90px;
`

const Content = styled.div`
  margin-left: 1rem;

  > h4 {
    line-height: 0.8em;
    font-family: 'Soehne';
    margin: 0.25rem 0;
  }

  > small {
    color: ${props => props.theme.colours.silver};
  }
`

LinkCard.propTypes = {
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    key: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired
}

function LinkCard({image, title, date, key, url}) {
    return (
        <Holder key={key} href={url} rel="noreferrer noopener">
            <ImageWrapper>
                <GatsbyImage alt={image.title} image={image.gatsbyImageData}/>
            </ImageWrapper>
            <Content>
                <h4>{title}</h4>
                <small>{date}</small>
            </Content>
        </Holder>
    )
}

export default LinkCard